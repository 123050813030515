<template>
  <v-container>
    <p class="ml-2 mt-2">{{ title }}</p>
    <v-row justify="end" class="mb-2">
      <v-col cols="12" sm="5" md="4">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          v-model="search"
          :placeholder="$_strings.oracleData.SEARCH_CUSTOMER"
          @keyup.enter="handleSearch"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="handleSearch">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      calculate-widths
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page
      }"
    >
      <template v-slot:[`item.actions`]={item}>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              small
              icon
              @click="() => showDialog(item, { resend: false})"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DETAIL}}</span>
        </v-tooltip>
        <v-tooltip v-if="menuFunctions.RESEND_MASTER_CUSTOMER" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="orange"
              small
              icon
              :loading="item.isLoading"
              @click="() =>resendOracleCode(item)"
            >
              <v-icon>
                mdi-cached
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.oracleData.RESEND_TO_ORACLE}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <DialogCustomerHistory ref="dialogCustomerHistory" />
  </v-container>
</template>
<script>
import {
  handleSortBy,
  handlerPagination,
  defaultPagination,
  skipEmptyStringObject,
} from '@/helper/commonHelpers';
import DialogCustomerHistory from './Dialog/CustomerHistory';

export default {
  components: {
    DialogCustomerHistory,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: this.$route.query.search || '',
      isLoading: false,
      headers: [
        {
          text: this.$_strings.oracleData.CUSTOMER_CODE,
          value: 'codeOracle',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.oracleData.CUSTOMER_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.oracleData.SHIP_TO_LOCATION,
          value: 'addressCity',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.oracleData.BILL_TO_LOCATION,
          value: 'billingCity',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    handleSearch() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
            page: 1,
          },
        });
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.fetchData();
        }
      }
    },
    async showDialog(item, { resend }) {
      const res = await this.fetchDetail(item.id);
      this.$refs.dialogCustomerHistory.dialog = true;
      this.$refs.dialogCustomerHistory.form.id = item.id;
      this.$refs.dialogCustomerHistory.form.jsonBody = JSON.stringify(res.data, null, 2);
    },
    async fetchData() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const {
          page, itemsPerPage: size, sortBy, sortDesc,
        } = this.pagination;
        const _filter = skipEmptyStringObject({
          ...this.filter,
          page: page - 1,
          size,
          sort: handleSortBy({ sortBy, sortDesc }),
          search: this.search,
        });
        const result = await this.$_services.masterCustomer.getListMasterCustomer(_filter);
        this.items = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async resendOracleCode(item) {
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.masterCustomer.resendOracleCode(item.id);
        this.$dialog.notify.success('Berhasil mengirim ulang ke Oracle');
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async fetchDetail(id) {
      try {
        this.$root.$loading.show();
        const result = await await this.$_services.masterCustomer.getPayload(id);
        return result;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
